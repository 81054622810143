import axios from "axios";
import { baseUrl, headers } from "../config/env";

export function createDiary(data) {
  return axios.post(baseUrl + "/diary", data, { headers });
}
export function fetchUserDiaryList(params) {
  return axios.get(baseUrl + `/diary/list`, { params, headers });
}
export function fetchDiaryDetails(id) {
  return axios.get(baseUrl + `/diary/${id}`, { headers });
}
export function deleteDiary(id) {
  return axios.delete(baseUrl + `/diary/${id}`, { headers });
}
export function updateDiary(data) {
  return axios.put(baseUrl + `/diary`, data, { headers });
}
