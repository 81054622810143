<template>
  <div class="container mypage withTop">
    <section class="contents farmList daily">
      <article class="calendar">
        <Calendar ref="calendar" is-expanded :attributes="attributes" @dayclick="handleDate"></Calendar>
      </article>
      <article class="inner">
        <h3>
          {{ moment(date).format("M월 D일") }}
          <p class="flr">
            총 등록일지 <span>{{ dailyList.length }}</span
            >건
          </p>
        </h3>
        <ul>
          <router-link :to="{ name: 'dailyDetail', query: { id: data._id } }" tag="li" class="box" v-for="(data, i) in dailyList" :key="i">
            <p><span>작업일</span>{{ moment(data.date).format("YYYY.MM.DD") }}</p>
            <p><span>작물</span>{{ data.crop }}</p>
            <p>
              <span>영농작업</span>
              <span v-if="data.category.includes('category1')">농약</span>
              <span v-if="data.category.includes('category2')">관수</span>
              <span v-if="data.category.includes('category3')">해충기록</span>
              <span v-if="data.category.includes('category4')">장비정비</span>
              <span v-if="data.category.includes('category5')">수확</span>
              <span v-if="data.category.includes('category6')">출고</span>
              <span v-if="data.category.includes('category7')">기타</span>
            </p>
          </router-link>
        </ul>
      </article>
    </section>
    <router-link :to="{ name: 'dailyRegister', query: { farmer: farmer } }" tag="button" class="floatingBtn">
      <span class="material-icons-round"> add </span>
    </router-link>
  </div>
</template>
<script>
import { fetchUserDiaryList } from "@/api/diary";
import moment from "moment";
import Calendar from "v-calendar/lib/components/calendar.umd";
export default {
  components: {
    Calendar,
  },
  data() {
    return {
      moment: moment,
      allDiaryList: [],
      dailyList: [],
      farmer: "",
      date: moment().format("YYYY-MM-DD"),
      attributes: [
        {
          key: "today",
          highlight: true,
          dates: new Date(),
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 1);
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
    this.$store.dispatch("SET_PAGE_NAME", "영농일지");
  },
  mounted() {
    if (this.$route.query.id) {
      this.farmer = this.$route.query.id;
    }
    this.getDiaryList();
  },

  methods: {
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    handleDate(day) {
      if (day) {
        this.date = day.id;
        this.attributes[0].dates = this.date;
      }

      this.dailyList = this.allDiaryList.filter((item) => moment(item.date).format("YYYY-MM-DD") == this.date);
    },
    getDiaryList() {
      let params = {
        date: new Date(),
        farmer: this.farmer,
      };
      fetchUserDiaryList(params).then((res) => {
        if (res.data.status == 200) {
          this.allDiaryList = res.data.data;
          this.handleDate();
          this.allDiaryList.forEach((item) => {
            this.attributes.push({ dot: true, dates: item.date });
          });
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
  },
};
</script>
